<!--
 * @Description: 消息管理模块
 * @Author: zhang zhen
 * @Date: 2023-08-10 21:21:57
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-18 23:47:25
 * @FilePath: /page-sass/src/views/messageManagement/index.vue
-->
<template>
  <div class="RequirementPool">
    <a-tabs v-model="queryParams.msgCategory" @change="handleLoadData('1')">
      <a-tab-pane :key="1">
        <span class="tab-title" slot="tab">
          <span class="title">系统消息</span>
          <a-badge :count="countInfo.msgCategory_1 || 0" :overflow-count="999"
            :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_1" />
          <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
        </span>
      </a-tab-pane>
      <a-tab-pane :key="2">
        <span class="tab-title" slot="tab">
          <span class="title">服务消息</span>
          <a-badge :count="countInfo.msgCategory_2 || 0" :overflow-count="999"
            :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_2" />
          <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
        </span>
      </a-tab-pane>
      <a-tab-pane :key="3">
        <span class="tab-title" slot="tab">
          <span class="title">客户消息</span>
          <a-badge :count="countInfo.msgCategory_3 || 0" :overflow-count="999"
            :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_3" />
          <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
        </span>
      </a-tab-pane>
    </a-tabs>
    <div class="space">
      <a-button @click="handleBatchDelete">批量删除</a-button>
      <a-button @click="showAllDelete = true">全部删除</a-button>
      <a-button @click="handleReadBatch">标记已读</a-button>
      <a-button @click="showReadAll = true">全部已读</a-button>
    </div>
    <div class="table-content">
      <a-table rowKey="id" :columns="columns" :loading="loading" :data-source="dataSource" :pagination="false"
        :expandIcon="customExpandIcon" style="margin-bottom: 16px"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
        <template slot="ackState" slot-scope="text, record">
          <span class="tag tag_2" v-if="text == 1">已读</span>
          <span class="tag tag_1" v-else>未读</span>
        </template>
        <p class="inner-box" slot="expandedRowRender" slot-scope="record" style="margin: 0">
          <!-- <div class="message-info">
               
           </div> -->
          <a-tooltip placement="topLeft">
            <template slot="title">
              {{ record.description || '' }}
            </template>
            <span class="desc">{{ record.description || '' }}</span>
            <!-- <a @click="handleLoadInfo(record.orderId)">{{ record.description || '' }}</a> -->
          </a-tooltip>
          <a @click="handleJumpInfo(record)" v-if="record.linkDesc">{{ record.linkDesc || '' }}</a>

          <!-- <a style="opacity: 0">占位置</a>
          <a @click="handleSwitchNav(record)" class="action-btn" v-if="record.ackState == 0">确认</a> -->
        </p>
        <template slot="operation" slot-scope="text, record">
          <a @click="handleDelete(record)">删除</a>
        </template>
      </a-table>
    </div>
    <ZPagination :total="pageNation.total" :current="pageNation.pageNum" :pageSize="pageNation.pageSize"
      @handlePageChange="handleChangePage" />
    <!-- 提示语句 -->
    <a-modal title="温馨提示" :visible="visible" centered @ok="handleOk(1)" @cancel="handleCancel">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>确定删除该信息吗？</span>
      </div>
    </a-modal>
    <!-- 全部已读 -->
    <a-modal title="温馨提示" :visible="showReadAll" centered @ok="handleReadAll" @cancel="showReadAll = false">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>确定将消息标记为全部已读吗？</span>
      </div>
    </a-modal>
    <!-- 批量删除 -->
    <a-modal title="温馨提示" :visible="showBatchDelete" centered @ok="handleOk(2)" @cancel="handleCancel">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>确定删除这{{ selectedRowKeys.length }}条消息吗？</span>
      </div>
    </a-modal>
    <!-- 批量已读 -->
    <a-modal title="温馨提示" :visible="showNeedChooseRead" centered @ok="handleReadBatchOk" @cancel="showNeedChooseRead = false">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>确定把这{{ selectedRowKeys.length }}条消息设置为已读吗？</span>
      </div>
    </a-modal>
    <!-- 全部删除 -->
    <a-modal title="温馨提示" :visible="showAllDelete" centered @ok="handleDeleteALL" @cancel="showAllDelete = false">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>确定删除所有消息消息吗？</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import { postAction, getAction } from '@/api/manage'
import { mapGetters } from 'vuex'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import Notice from '@/mixins/notice'
import MessageLoader from '@/mixins/messageJumper'
export default {
  name: 'messageManagement',
  components: {
    ZPagination,
    EmptyArea,
  },
  mixins: [Notice, MessageLoader],
  data() {
    return {
      showNeedChooseRead: false,
      showBatchDelete: false,
      showAllDelete: false,
      showReadAll: false,
      visible: false,
      loading: false,
      showMore: false,
      rangeDate: [],
      queryParams: {
        msgCategory: 1,
      },
      disableCreated: true,
      targetPurchaseId: null,
      pageNation: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      columns: [
        {
          title: '标题',
          dataIndex: 'bizTypeLabel',
          width: '35%',
          key: 'title',
        },
        {
          title: '发送时间',
          dataIndex: 'createTime',
          key: 'createTime',
        },
        {
          title: '类型',
          dataIndex: 'ackState',
          key: 'ackState',
          scopedSlots: { customRender: 'ackState' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      dataSource: [
      ],
      url: {
        list: '/remind/msg/pageQuery',
      },
      info: {},
      selectedRowKeys: [],

    }
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        this.handleLoadNoticeCount()
        if (query.msgCategory) {
          this.queryParams.msgCategory = Number(query.msgCategory);
          this.handleLoadData(1)
        } else {
          this.queryParams.msgCategory = 1;
          this.handleLoadData(1)
        }
      },
      immediate: true,
    },
  },
  methods: {
    handlePlay(record, e, onExpand, expanded) {
      record.ackState == 0 && this.handleSwitchNav(record)
      onExpand(record, e)
    },
    customExpandIcon(props) {
      const { expanded, onExpand, record } = props


      return props.expanded ? (
        <a-icon type="caret-down" onClick={(e) => onExpand(record, e)} />
      ) : (
        <a-icon type="caret-right" onClick={(e) => this.handlePlay(record, e, onExpand, expanded)} style="opacity: 0.25" />
      )
    },
    ...mapGetters(['setUserInfo']),
    handleSwitchNav({ id }) {
      // 详情未提供
      getAction('/remind/msg/ack', { id }).then(res => {
        const { success, message } = res;
        if (success) {
          this.$message.success(message)
          this.handleLoadData(1)
          this.handleLoadNoticeCount()
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleChangePage({ pageNum, pageSize }) {
      this.pageNation.pageNum = pageNum
      this.pageNation.pageSize = pageSize
      this.handleLoadData()
    },
    /* 删除 */
    handleDelete(item) {
      //删除
      this.targetPurchaseId = item.id
      this.visible = true
    },
    handleBatchDelete() {
      if (!this.selectedRowKeys.length) {
        return this.$message.warning('请选择要删除的消息')
      }
      //批量删除
      this.showBatchDelete = true
    },
    /* 删除所有 */
    handleDeleteALL() {
      getAction('/remind/msg/deleteAll', { msgCategory: this.queryParams.msgCategory }).then(res => {
        const { success, message } = res;
        if (success) {
          // 
          this.showAllDelete = false;
          this.handleLoadNoticeCount();
          this.handleLoadData(1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 全部已读 */
    handleReadAll() {
      getAction('/remind/msg/ackAll', { msgCategory: this.queryParams.msgCategory }).then(res => {
        const { success, message } = res;
        if (success) {
          // 
          this.showReadAll = false
          this.handleLoadNoticeCount();
          this.handleLoadData(1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 批量已读 */
    handleReadBatch() {
      if (!this.selectedRowKeys.length) {
        return this.$message.warning('请选择要设置为已读的消息')
      }
      //批量删除
      this.showNeedChooseRead = true
    },
    /* 批量已读接口 */
    handleReadBatchOk() {
      postAction('/remind/msg/ackByIds', this.selectedRowKeys).then(res => {
        const { success, message } = res;
        if (success) {
          // 
          this.showNeedChooseRead = false;
          this.handleLoadNoticeCount();
          this.handleLoadData(1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 确认删除事件 */
    handleOk(type = 1) {
      postAction('/remind/msg/deleteByIds', type == 1 ? [this.targetPurchaseId] : this.selectedRowKeys).then(res => {
        const { success, message } = res;
        if (success) {
          // 
          this.visible = false;
          this.showBatchDelete = false;
          this.handleLoadNoticeCount();
          this.handleLoadData(1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleSearch() {
      if (this.rangeDate.length) {
        const [publishStartTime, publishEndTime] = this.rangeDate
        this.queryParams['publishStartTime'] = publishStartTime
        this.queryParams['publishEndTime'] = publishEndTime
      }
      this.handleLoadData(1)
    },
    reaLoad() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      }
      this.queryParams = {
        msgCategory: 1
      }
      this.rangeDate = []
      this.dataSource = []
      this.handleLoadData(1)
    },
    handleLoadData(flag) {
      console.log(this.queryParams.msgCategory)
      if (this.queryParams.msgCategory == '3') {
        this.queryParams.msgCategory = 1
        return this.handleJumpChat();
      }
      if (flag == 1) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0,
        }
        this.selectedRowKeys = [];
      }
      const { pageNum, pageSize } = this.pageNation
      this.loading = true
      postAction(this.url.list, {
        businessId: this.setUserInfo().businessId,
        pageNum,
        pagesSize: pageSize,
        ...this.queryParams,
      }).then((res) => {
        const { success, data } = res
        this.loading = false
        if (success) {
          const { total, list } = data
          this.pageNation.total = total
          this.dataSource = list
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.showBatchDelete = false
      this.targetPurchaseId = null
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
  },
}
</script>

<style lang="less" scoped>
.RequirementPool {
  min-height: calc(100vh - 111px);
  background-color: white;
  padding: 10px 25px 10px 24px;
  box-sizing: border-box;
  border-radius: 3px;

  &-searchBar {
    border-radius: 8px;
    padding-bottom: 8px;
    margin-bottom: 15px;

    .ant-btn {
      &+.ant-btn {
        margin-left: 8px;
        margin-right: 22px;
      }
    }

    ::v-deep .ant-form-item {
      margin-bottom: 0;
    }
  }


  .table-content {
    min-height: calc(100vh - 285px);

    ::v-deep .ant-table-placeholder {
      height: calc(100vh - 343px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    // padding: 0 25px 0;
    .flexTitle {
      .flexLayout(@justifyContent: flex-start);
    }

    &-title,
    .table-row {
      .flexLayout();
      background: #f2f2f2;
      text-align: center;
      font-weight: bold;

      .title {
        width: 25%;
        text-align: left;

        .label {
          display: inline-block;
          margin-right: 6px;
          max-width: calc(100% - 58px);
          .text-emphasis();
        }

        .cover {
          width: 52px;
          height: auto;
        }
      }

      .kind {
        width: 20%;
      }

      .num {
        width: 13%;
      }

      .provider {
        width: 20%;
      }

      .action {
        width: 20%;

        .ant-btn {
          width: 100px;
        }
      }
    }

    &-title {
      padding: 20px 16px 20px 40px;
      margin-bottom: 15px;
      font-size: 13px;

      .title {
        text-align: center;
      }
    }

    ::v-deep .ant-collapse {
      background: #fffff8;
      border: none;
    }

    ::v-deep .ant-collapse-item {
      background: #f2f2f2;
      margin-bottom: 15px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      overflow: hidden;
    }

    ::v-deep .ant-form-item {
      margin-bottom: 6px;
    }
  }

  ::v-deep .ant-tabs-tab {
    padding: 12px 0;

    .tab-title {
      display: flex;
      align-items: center;
    }

    span.title {
      font-size: 16px;
      margin-right: 8px;
      font-weight: 500;
    }
  }
}

.top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  ::v-deep .ant-select-lg {
    font-size: 14px;
  }

  ::v-deep .ant-input-lg {
    font-size: 14px;
  }

  .ant-btn {
    width: 97px;
    height: 38px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 500;
    margin-right: 8px;

    &:not(:last-of-type):hover {
      color: #ff9a57;
    }

    &+.ant-btn {
      color: #fff;
    }
  }
}

::v-deep .ant-input-number-lg {
  font-size: 14px;
}

::v-deep .ant-badge-status-text {
  color: rgba(0, 0, 0, 0.85);
}

.space {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 0 8px;
  margin-bottom: 16px;
}

// ::v-deep .ant-table-tbody>tr>td {
//   border-bottom: none;
// }

// ::v-deep .ant-table-tbody {
//   .ant-table-row:last-of-type td {
//     border-bottom: 1px solid #EFEFEF;
//   }
// }
// #F9F9F9
::v-deep .ant-table {
  color: #131212;
}

::v-deep .ant-table-thead>tr>th {
  background: #F4F5F7;
  border-bottom: none;
  font-size: 14px;
  font-weight: 600;
  color: #131212;
  padding: 12px 16px;
}

// ::v-deep .ant-table-row {
//   // background-color: #f9f9f9;
//   // border-bottom: 1px solid #EFEFEF;
// }

::v-deep .ant-table-expanded-row {
  background-color: #F9F9F9;

  // &:hover {
  //   background-color: #fff;
  // }
  td[colspan=5] {
    padding-left: 76px;
  }
}

img.tableImag {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-right: 8px;
}

::v-deep .ant-badge-status-dot {
  width: 8px;
  height: 8px;
}

.info-card {
  font-size: 16px;
  color: #131212;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.inner-box {
  position: relative;
  width: 100%;
  // overflow: hidden;
  color: #131212;
  padding-left: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  // span {
  //   .text-ellipsis-multi(1)
  // }
  .desc {
    .text-ellipsis-multi(1);
    color: #000000D9;
    margin-right: 8px;
  }

  a {
    cursor: pointer;
  }

  .action-btn {
    position: absolute;
    bottom: 0;
    right: 155px;
  }
}

.message-info {
  display: inline-block;
  width: 100% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #131212;
  box-sizing: border-box;
}

::deep .ant-tooltip-open {
  width: 100%;
  overflow: hidden;
}

.tag {
  display: inline-block;
  width: 40px;
  height: 24px;
  border-radius: 3px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;

  &_1 {
    background: #EE42611A;
    color: #EE4261;
  }

  &_2 {
    background: #E6FAF3;
    color: #06BF7F;
  }
}
</style>
